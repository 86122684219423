@font-face {
  font-family: "Abril Fatface";
  src: url("../fonts/AbrilFatface-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

html,
body {
  margin: 0;
  min-height: 100vh;
  overflow: auto !important;
  padding: 0 !important;
}
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
}
figure {
  margin: 0;
}
h1,
h3,
h4,
p {
  margin: 0;
}

img {
  height: 100%;
  float: left;
}

a {
  color: #000;
  text-decoration: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(169 178 191 / 32%) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.7em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(169 178 191 / 32%);
  border-radius: 10px;
}

.page-title {
  margin-top: 8px !important;
  color: #333;
  padding: 10px;
  text-align: center;
  font-size: 24px !important;
  font-weight: bold !important;
}

.page-title-login {
  margin-top: 8px !important;
  color: #999;
  padding-bottom: 15px;
  text-align: center;
  font-size: 30px !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}
